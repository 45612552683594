import React from 'react';
import Eventd from '@lumoslabs/lumos_events_js';
import PropTypes from 'prop-types';

const {
  GATSBY_EVENT_URL = '/lumos_events/new.js',
  GATSBY_EVENT_DEBUG = false,
  GATSBY_EVENT_DISABLED = false,
} = process.env;

class EventdInit extends React.Component {
  static init() {
    Eventd.eventUrl = GATSBY_EVENT_URL;
    Eventd.disabled = GATSBY_EVENT_DISABLED;
    Eventd.debugMode = GATSBY_EVENT_DEBUG;
  }

  componentDidMount() {
    if (typeof window !== undefined) {
      EventdInit.init();
      Eventd.event('page_view');
    }
  }

  render() {
    return null;
  }
}

EventdInit.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default EventdInit;
