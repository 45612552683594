import React from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const userFirstNameKey = 'lumos/user/firstName';
const userPreferredLanguageKey = 'lumos/user/preferredLanguage';

export default function withCurrentUser(WrappedComponent) {
  class WithCurrentUser extends React.Component {
    constructor(props) {
      super(props);
      this.fetchCurrentUser = this.fetchCurrentUser.bind(this);
      this.state = {
        firstName: null,
        preferredLanguage: null,
        userLoggedIn: false
      };
    }

    componentDidMount() {
      this.fetchCurrentUser();
    }

    fetchCurrentUser() {
      const { GATSBY_LUMOSITY_HOST } = process.env;
      fetch(`${GATSBY_LUMOSITY_HOST}/api/v2/session/basic_user_info`, {
        credentials: 'include'
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(({ first_name: firstName, preferred_language: preferredLanguage }) => {
              localStorage.setItem(userFirstNameKey, firstName);
              localStorage.setItem(userPreferredLanguageKey, preferredLanguage);
              this.setState({ firstName, preferredLanguage, userLoggedIn: true });
            });
          }
        })
        .catch((response) => {
          // eslint-disable-next-line no-console
          console.log('error', response);
        });
    }

    render() {
      const { firstName, preferredLanguage, userLoggedIn } = this.state;
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent currentUser={{ firstName, preferredLanguage, userLoggedIn }} {...this.props} />;
    }
  }

  WithCurrentUser.displayName = `WithCurrentUser(${getDisplayName(WrappedComponent)})`;
  return WithCurrentUser;
}
