/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import Link from '../Link/NonSSGLink';

const styles = StyleSheet.create({
  image: {
    width: 132,
    height: 'auto',
    '@media (max-width: 767.98px)': {
      margin: '0 auto'
    }
  }
});

function Button({ src, to, ...attrs }) {
  return (
    <Link to={to} {...attrs}>
      <img src={src} className={css(styles.image)} alt="Get the app" />
    </Link>
  );
}

Button.propTypes = {
  src: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default Button;
