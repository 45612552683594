import React from 'react'
import { StyleSheet } from 'aphrodite'

const LinkStyles = StyleSheet.create({
  noLink: {
    display: 'block',
    boxSizing: 'border-box',
    color: 'rgba(0,0,0,0.62)',
    margin: '12px 0',
    fontSize: '0.95em',
    lineHeight: '1.4em',
    fontWeight: 700,
    letterSpacing: 'normal',
    textRendering: 'optimizelegibility',
    '@media (max-width: 767.98px)': {
      fontSize: '1.027em',
      textAlign: 'center',
    },
  },
  link: {
    display: 'block',
    boxSizing: 'border-box',
    color: 'rgba(0,0,0,0.62)',
    margin: '12px 0',
    fontSize: '0.95em',
    lineHeight: '1.4em',
    fontWeight: 500,
    letterSpacing: 'normal',
    textTransform: 'none',
    textRendering: 'optimizelegibility',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
    '@media (max-width: 767.98px)': {
      fontSize: '1.027em',
      textAlign: 'center',
    },
  },
})

export default LinkStyles
