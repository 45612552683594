import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import SSGLink from '../Link/SSGLink';
import LinkGroup from '../LinkGroup';
import i18n from '../../../../utils/i18n';
import Styles from '../Link/Styles';

function LumosLabsLinkGroup({ locale }) {
  return (
    <LinkGroup headerText="Lumos Labs">
      <SSGLink to={`/${locale}/about/`} data-track-link-click="about">
        {i18n.t('footer.aboutUs')}
      </SSGLink>
      {locale !== 'en' && (
        <>
          <SSGLink to={`/${locale}/resources/`} data-track-link-click="press">
            {i18n.t('footer.press')}
          </SSGLink>
          <SSGLink to={`/${locale}/jobs/`} data-track-link-click="careers">
            {i18n.t('footer.careers')}
          </SSGLink>
        </>
      )}
      {locale === 'en' && (
        <>
          <a href="https://www.lumoslabs.com/press" data-track-link-click="press" className={css(Styles.link)}>
            {i18n.t('footer.press')}
          </a>
          <a href="https://www.lumoslabs.com/careers" data-track-link-click="careers" className={css(Styles.link)}>
            {i18n.t('footer.careers')}
          </a>
          <SSGLink to="/en/blog/" data-track-link-click="blog">
            {i18n.t('footer.blog')}
          </SSGLink>
        </>
      )}
    </LinkGroup>
  );
}

LumosLabsLinkGroup.propTypes = {
  locale: PropTypes.string.isRequired
};

export default LumosLabsLinkGroup;
