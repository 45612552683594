import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  offscreen: {
    position: 'absolute',
    left: -10000,
    top: 'auto',
    width: 1,
    height: 1,
    overflow: 'hidden'
  }
});

// This calls a 1x1 with a ?locale to coerce Rails to setting the locale in its session,
// so when we enter the rails app we'll have a locale that matches the user's ssg pages
const Localizer = ({ locale, location }) => {
  // For now, avoid throwing error when lumos_rails is unavailable in the current environment.
  if (location.hostname === 'localhost' && location.port === '8000') {
    return null;
  }
  return (
    <img
      className={css(styles.offscreen)}
      src={`/localize.gif?visitor_locale=${locale}&ts=${+new Date()}`}
      alt="localizer pixel"
    />
  );
};

Localizer.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.shape({
    hostname: PropTypes.string,
    port: PropTypes.string
  })
};

Localizer.defaultProps = {
  locale: 'en',
  location: {
    hostname: 'localhost',
    port: '8000'
  }
};

export default Localizer;
