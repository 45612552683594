import i18n from 'i18next';

import LngDetector from 'i18next-browser-languagedetector';
import UrlLangDetector from './urlLangDetector';

import * as enStrings from '../locales/en.json';
import * as esStrings from '../locales/es.json';
import * as deStrings from '../locales/de.json';
import * as frStrings from '../locales/fr.json';
import * as jaStrings from '../locales/ja.json';
import * as koStrings from '../locales/ko.json';
import * as ptStrings from '../locales/pt.json';

const lngDetector = new LngDetector();
lngDetector.addDetector(UrlLangDetector);

const detection = {
  order: ['urlDetector', 'navigator']
};

i18n.use(lngDetector).init({
  fallbackLng: 'en', // FIXME: Grab this from dotenv somehow
  debug: false,
  defaultNS: 'default',
  detection,
  ns: 'default',
  resources: {
    en: enStrings,
    de: deStrings,
    es: esStrings,
    fr: frStrings,
    ja: jaStrings,
    ko: koStrings,
    pt: ptStrings
  }
});

export default i18n;
