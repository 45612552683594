import React from 'react';
import { css } from 'aphrodite';
import Styles from './Styles';

// Use ExternalLink for links that resolve to pages not on ssg.
// This includes pages outside of lumosity.com, as well as
// anything that is rendered by something other than lumos_ssg
function NonSSGLink({ children, to, ...attrs }) {
  return (
    <a className={css(Styles.link)} href={to} data-track data-track-location="footer" {...attrs}>
      {children}
    </a>
  );
}

export default NonSSGLink;
