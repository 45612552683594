import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Col } from 'reactstrap';

// utils
import i18n from '../../../utils/i18n';

// styles
import * as layout from '../../../styles/helpers/layout';
import { blackAt89, fonts } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  copyright: {
    ...fonts.h6,
    color: blackAt89,
    textAlign: 'left',
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      textAlign: 'center'
    }
  }
});

function Copyright() {
  return (
    <Col xs="12" md="8">
      <p className={css(styles.copyright)}>{`Copyright © ${new Date().getFullYear()} Lumos Labs, Inc. ${i18n.t(
        'footer.allRightsReserved'
      )}`}</p>
    </Col>
  );
}

export default Copyright;
