import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { css, StyleSheet } from 'aphrodite';
import { lumosBlue, lumosWhite, whiteAt98 } from '@lumoslabs/react_lux';

import withCurrentUser from 'components/utils/withCurrentUser';

// style assets
import * as layout from '../../../styles/helpers/layout';

// child components
import DropdownGroup from './DropdownGroup';
import HamburgerMenu from './HamburgerMenu';
import LogoLink from './LogoLink';
import LinkGroup from './LinkGroup';

export const headerId = 'lumos-header';

const styles = StyleSheet.create({
  fullSpan: {
    width: '100vw'
  },
  sticky: {
    position: 'fixed',
    top: 0,
    zIndex: 4
  },
  container: {
    ...layout.centerVertical,
    height: 68,
    position: 'relative',
    backgroundColor: 'transparent',
    transition: 'background-color 200ms linear',
    padding: '0 30px',
    [layout.mediaQuery.maxWidth.bootstrap.md]: {
      height: 56
    }
  },
  contrastContainer: {
    backgroundColor: whiteAt98,
    transition: 'all 200ms linear',
    boxShadow: '0 1.2px 4px 0 rgba(0, 0, 0, 0.2), 0 0.5px 1px 0 rgba(0, 0, 0, 0.04)'
  },
  flatContainer: {
    boxShadow: 'none'
  },
  whiteBackground: {
    backgroundColor: lumosWhite
  },
  logoColumn: {
    zIndex: 4,
    ...layout.centerVertical,
    ...layout.alignLeft,
    [layout.mediaQuery.maxWidth.bootstrap.md]: {
      ...layout.centerHorizontal
    }
  },
  linkGroupColumn: {
    ...layout.centerVertical,
    ...layout.alignRight,
    [layout.mediaQuery.maxWidth.bootstrap.md]: {
      display: 'none'
    }
  }
});

function Header({ isStickyHeader, accentColor, renderedAsWebview, locale, forceWhiteBackground, currentUser }) {
  const loggedIn = currentUser.userLoggedIn;

  if (renderedAsWebview) {
    return <div />;
  }

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [applyContrastStyling, setApplyContrastStyling] = useState(false);

  function handleWindowScroll() {
    const windowScrolled = window.pageYOffset > 10;
    if (windowScrolled && !applyContrastStyling) {
      setApplyContrastStyling(true);
    } else if (!windowScrolled && !dropdownIsOpen) {
      setApplyContrastStyling(false);
    }
  }

  useEffect(() => {
    if (!isStickyHeader) return undefined;
    window.addEventListener('scroll', handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  });

  /*
    TODO: it should be possible to refactor windowScrolled to the component state
    You would need to continuously update it within the scroll handler using setSTate,
    and then you would need to move the if/else logic to a callback that fires after
    the setState on windowScrolled is completed
  */

  function toggleDropdown() {
    const windowScrolled = window.pageYOffset > 10;
    setDropdownIsOpen(!dropdownIsOpen);
    setApplyContrastStyling(windowScrolled);
  }
  const [isUnsupportedBrowserOnHomepage, setIsUnsupportedBrowserOnHomepage] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !!window.MSInputMethodContext &&
      !!document.documentMode &&
      window.location.pathname === `/${locale}/`
    ) {
      setIsUnsupportedBrowserOnHomepage(true);
    }
  });

  const applyFixedStyling = !isStickyHeader || dropdownIsOpen || isUnsupportedBrowserOnHomepage;

  const applyContrastStyleToLinks = applyContrastStyling || applyFixedStyling || forceWhiteBackground;
  const showBlueLogo = applyContrastStyleToLinks || dropdownIsOpen;

  // top-level header tag added for semantic HTML5 (SEO, accessibility)
  return (
    <React.Fragment>
      <header className={css(styles.fullSpan, isStickyHeader && styles.sticky)} id={headerId} data-cy="lumosity-header">
        <Container
          fluid
          className={css(
            styles.container,
            (applyFixedStyling || applyContrastStyling) && styles.contrastContainer,
            (applyFixedStyling || dropdownIsOpen) && styles.flatContainer,
            forceWhiteBackground && styles.whiteBackground
          )}
        >
          <Row className={css(styles.fullSpan)}>
            <HamburgerMenu showBlueBun={showBlueLogo} dropdownIsOpen={dropdownIsOpen} toggleDropdown={toggleDropdown} />
            <Col xs="12" md="3" className={css(styles.logoColumn)}>
              <LogoLink showBlueLogo={showBlueLogo} locale={locale} />
            </Col>
            <Col xs="9" className={css(styles.linkGroupColumn)}>
              <LinkGroup
                applyContrastStyling={applyContrastStyleToLinks}
                accentColor={accentColor}
                locale={locale}
                loggedIn={loggedIn}
              />
            </Col>
          </Row>
        </Container>
      </header>
      <DropdownGroup
        dropdownIsOpen={dropdownIsOpen}
        locale={locale}
        toggleDropdown={toggleDropdown}
        loggedIn={loggedIn}
      />
    </React.Fragment>
  );
}

Header.propTypes = {
  isStickyHeader: PropTypes.bool,
  accentColor: PropTypes.string,
  renderedAsWebview: PropTypes.bool,
  locale: PropTypes.string,
  forceWhiteBackground: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    preferredLanguage: PropTypes.string,
    userLoggedIn: PropTypes.bool
  })
};

Header.defaultProps = {
  isStickyHeader: false,
  accentColor: lumosBlue,
  renderedAsWebview: false,
  locale: 'en',
  currentUser: null
};

export default withCurrentUser(Header);
