import React from 'react';
import { lumosOrange } from '@lumoslabs/react_lux';
import { Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';

// utils
import i18n from '../../../utils/i18n';

// style assets
import * as layout from '../../../styles/helpers/layout';

// icons
import ScrollToTopIconWhite from './images/ScrollToTopIconWhite.svg';
import ScrollToTopIconOrange from './images/ScollToTopIconOrange.svg';

const styles = StyleSheet.create({
  linkCol: {
    ...layout.alignRight,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      ...layout.centerHorizontal,
      marginTop: 20
    }
  },
  linkContainer: {
    height: 60,
    width: 60,
    padding: 10,
    boxSizing: 'border-box',
    borderRadius: '50%',
    border: `2px solid ${lumosOrange}`,
    transition: 'background-color 300ms cubic-bezier(0.19, 1, 0.22, 1)',
    ':hover': {
      backgroundColor: lumosOrange
    }
  }
});

function slideToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

class ScrollToTopLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShortPage: false,
      hover: false
    };
  }

  componentDidMount() {
    // work must be done in CDM because document is unavailable during gatsby build process
    const documentHeight = document.body.clientHeight;
    const reasonablyTallDocument = 2000;

    this.setState({ isShortPage: documentHeight && documentHeight < reasonablyTallDocument });
  }

  render() {
    const { isShortPage, hover } = this.state;
    if (isShortPage) {
      return null;
    }

    return (
      <Col xs="12" md="4" className={css(styles.linkCol)}>
        <button
          type="button"
          className={css(styles.linkContainer)}
          data-track="true"
          data-track-button-press="scroll_to_top"
          data-track-location="footer"
          onMouseEnter={() => this.setState({ hover: !hover })}
          onMouseLeave={() => this.setState({ hover: !hover })}
          onClick={() => slideToTop()}
        >
          <img src={hover ? ScrollToTopIconWhite : ScrollToTopIconOrange} alt={i18n.t('footer.scrollToTopAlt')} />
        </button>
      </Col>
    );
  }
}

export default ScrollToTopLink;
