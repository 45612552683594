import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

function Metatags(props) {
  const { title, canonical, description, thumbnail, imageWidth, imageHeight, locale, twitterImage, type } = props;
  const metaTags = [
    { property: 'og:title', content: title },
    { property: 'og:image', content: thumbnail && thumbnail },
    { property: 'og:image:secure_url', content: thumbnail && thumbnail },
    { property: 'og:description', content: description },
    { property: 'og:image:width', content: imageWidth },
    { property: 'og:image:height', content: imageHeight },
    { property: 'og:locale', content: locale },
    { property: 'og:type', content: type },
    { property: 'og:site_name', content: 'Lumosity' },
    { name: 'description', content: description },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@lumosity' },
    { name: 'twitter:creator', content: '@lumosity' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: twitterImage && `https:${twitterImage}` },
    { name: 'robots', content: 'index, follow' },
    { name: 'google-site-verification', content: process.env.GATSBY_SEARCH_VERIFICATION }
  ].filter(tag => tag.content);

  return (
    <StaticQuery
      query={graphql`
        query MetatagQuery {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={data => (
        <Helmet
          title={title}
          link={[{ rel: 'canonical', href: `${data.site.siteMetadata.siteUrl}${canonical}` }]}
          meta={metaTags}
        />
      )}
    />
  );
}

Metatags.propTypes = {
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  locale: PropTypes.string,
  twitterImage: PropTypes.string,
  type: PropTypes.string
};

Metatags.defaultProps = {
  locale: 'en',
  type: 'website',
  thumbnail: undefined,
  imageWidth: undefined,
  imageHeight: undefined,
  twitterImage: undefined
};

export default Metatags;
