export function gamesUrl(locale, loggedIn) {
  return loggedIn ? '/games' : `/${locale}/brain-games/`;
}

export function scienceUrl(locale) {
  return `/${locale}/science/`;
}

export function loginUrl() {
  return '/login';
}

export function signupUrl() {
  return '/sign_up';
}

export function startTrainingUrl() {
  return '/platform_check';
}

export function tryGamesUrl() {
  return '/games-lp/speed-match-mini/';
}

export function mindfulnessUrl() {
  return '/en/landing_pages/mindfulness';
}

export function figmentUrlBanner() {
  return 'https://www.lumosity.com/en/figment';
}

export function figmentUrlFooter() {
  return 'https://www.lumosity.com/en/figment';
}

export function showLogin(loggedIn) {
  return !loggedIn;
}

export function showSignUp(loggedIn) {
  return !loggedIn;
}

export function showGetStarted(loggedIn) {
  return !loggedIn;
}

export function showTryGames(locale, loggedIn) {
  return locale === 'en' && !loggedIn;
}

export function showStartTraining(loggedIn) {
  return loggedIn;
}
