import React from 'react';
import PropTypes from 'prop-types';
import NonSSGLink from '../Link/NonSSGLink';
import SSGLink from '../Link/SSGLink';
import LinkGroup from '../LinkGroup';
import i18n from '../../../../utils/i18n';

const helpUrl = 'https://www.lumosity.com/zendesk/authentication?return_to=https%3A%2F%2Fhelp.lumosity.com';

function HelpLinkGroup({ locale }) {
  return (
    <LinkGroup headerText={i18n.t('footer.help')}>
      <NonSSGLink to={helpUrl} data-track-link-click="help">
        {i18n.t('footer.helpCenter')}
      </NonSSGLink>
      <SSGLink to={`/${locale}/contact/`} data-track-link-click="contact">
        {i18n.t('footer.contactUs')}
      </SSGLink>
    </LinkGroup>
  );
}

HelpLinkGroup.propTypes = {
  locale: PropTypes.string.isRequired
};

export default HelpLinkGroup;
