import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import AppStoreEn from './images/app_store.png';
import AppStoreDe from './images/app_store.de.png';
import AppStoreEs from './images/app_store.es.png';
import AppStoreFr from './images/app_store.fr.png';
import AppStoreKo from './images/app_store.ko.png';
import AppStoreJa from './images/app_store.ja.png';
import AppStorePt from './images/app_store.pt.png';

const imageMap = {
  en: AppStoreEn,
  fr: AppStoreFr,
  es: AppStoreEs,
  de: AppStoreDe,
  ko: AppStoreKo,
  ja: AppStoreJa,
  pt: AppStorePt
};

function AppStoreButton({ locale }) {
  return (
    <>
      <Button
        data-track-button-press="goto_app_store"
        src={imageMap[locale]}
        to="https://apps.apple.com/app/lumosity-brain-training/id577232024"
      />
    </>
  );
}

AppStoreButton.propTypes = {
  locale: PropTypes.string.isRequired
};

export default AppStoreButton;
