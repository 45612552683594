import React from 'react';
import PropTypes from 'prop-types';

// utils
import i18n from '../../../../utils/i18n';
import {
  gamesUrl,
  showSignUp,
  showLogin,
  showTryGames,
  signupUrl,
  loginUrl,
  scienceUrl,
  tryGamesUrl,
  showStartTraining,
  startTrainingUrl
} from '../../utils/LinkUtils';

// child components
import NonSSGLink from '../Link/NonSSGLink';
import SSGLink from '../Link/SSGLink';
import LinkGroup from '../LinkGroup';

import HiddenOnMobile from '../../utils/HiddenOnMobile';

function LumosityLinkGroup({ locale, loggedIn }) {
  return (
    <LinkGroup headerText="Lumosity">
      {showSignUp(loggedIn) && (
        <NonSSGLink to={signupUrl()} data-track-link-click="goto_signup_footer">
          {i18n.t('footer.getStarted')}
        </NonSSGLink>
      )}
      {showLogin(loggedIn) && (
        <NonSSGLink to={loginUrl()} data-track-link-click="log_in">
          {i18n.t('footer.logIn')}
        </NonSSGLink>
      )}
      {showTryGames(locale, loggedIn) && (
        <HiddenOnMobile>
          <NonSSGLink to={tryGamesUrl()} data-track-link-click="goto_prereg_games">
            {i18n.t('footer.tryGames')}
          </NonSSGLink>
        </HiddenOnMobile>
      )}
      {showStartTraining(loggedIn) && (
        <NonSSGLink to={startTrainingUrl()} data-track-link-click="goto_start_training">
          {i18n.t('footer.startTraining')}
        </NonSSGLink>
      )}
      <SSGLink to={scienceUrl(locale)} data-track-link-click="goto_science">
        {i18n.t('footer.ourScience')}
      </SSGLink>
      <NonSSGLink to={gamesUrl(locale, loggedIn)} data-track-link-click="goto_games">
        {i18n.t('footer.ourGames')}
      </NonSSGLink>
    </LinkGroup>
  );
}

LumosityLinkGroup.propTypes = {
  locale: PropTypes.string,
  loggedIn: PropTypes.bool
};

LumosityLinkGroup.defaultProps = {
  locale: 'en',
  loggedIn: false
};

export default LumosityLinkGroup;
