import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  header: {
    fontSize: '1.273em',
    fontWeight: 'bold',
    '-webkit-font-smoothing': 'antialiased',
    lineHeight: '1.43',
    textTransform: 'none',
    textRendering: 'optimizelegibility',
    color: 'rgba(0,0,0,0.89)',
    marginBottom: 12,
    letterSpacing: 'normal',
    '@media (max-width: 767.98px)': {
      marginTop: 44,
      fontSize: '1.027em',
      textAlign: 'center'
    },
    '@media (max-width: 1280px)': {
      fontSize: '0.927em',
      lineHeight: '1.44',
      letterSpacing: 0.2
    },
    '@media (max-width: 2100px)': {
      fontSize: '1.027em',
      letterSpacing: -0.1
    }
  }
});

function LinkGroup({ headerText, children }) {
  return (
    <>
      <h3 className={css(styles.header)}>{headerText}</h3>
      {children}
    </>
  );
}

LinkGroup.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default LinkGroup;
