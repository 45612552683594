import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

// child components

import Link from './Link/NonSSGLink';
import Styles from './Link/Styles';
import * as layout from '../../../styles/helpers/layout';

const languageNames = {
  de: 'Deutsch',
  en: 'English',
  es: 'español',
  fr: 'français',
  ja: '日本語',
  ko: '한국어',
  pt: 'português'
};

function getAlternatePath(langCode, location, baseUrl) {
  return `${baseUrl}/${langCode}/${location.pathname.substring(4)}`;
}

function renderLink(langCode, locale, location, baseUrl) {
  const langName = languageNames[langCode];

  if (locale === langCode) {
    return (
      <div key="currentLang" className={css(Styles.noLink)}>
        &raquo;
        {langName}
      </div>
    );
  }

  const samePageDifferentLanguage = getAlternatePath(langCode, location, baseUrl);

  return (
    <Link
      key={`changeLangTo${langCode}`}
      to={samePageDifferentLanguage}
      data-track-link-click={`changeLanguageTo${langCode}`}
    >
      {langName}
    </Link>
  );
}

// Returns array of supported locales with current locale as first element
function supportedLocales(data, locale) {
  const supportedLocalesList = data.site.siteMetadata.localesSupported.split(',');
  const currentLocaleIndex = supportedLocalesList.indexOf(locale);
  supportedLocalesList.splice(currentLocaleIndex, 1);
  supportedLocalesList.unshift(locale);
  return supportedLocalesList;
}

const styles = StyleSheet.create({
  linksRow: {
    ...layout.alignLeft,
    marginBottom: 24,
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      ...layout.centerHorizontal,
      marginTop: 24
    }
  }
});

function LanguageLinks({ locale, location }) {
  return (
    <StaticQuery
      query={graphql`
        query LanguageLinkQuery {
          site {
            siteMetadata {
              localesSupported
              defaultLocale
              siteUrl
            }
          }
        }
      `}
      render={data => (
        <Row className={css(styles.linksRow)}>
          <Helmet>
            <link
              rel="alternate"
              hrefLang="x-default"
              href={getAlternatePath(data.site.siteMetadata.defaultLocale, location, data.site.siteMetadata.siteUrl)}
            />
            {supportedLocales(data, locale).map(langCode => (
              <link
                key={langCode}
                rel="alternate"
                hrefLang={langCode}
                href={getAlternatePath(langCode, location, data.site.siteMetadata.siteUrl)}
              />
            ))}
          </Helmet>
          {supportedLocales(data, locale).map(langCode => (
            <Col xs="4" md="auto" key={`LinkFor-${langCode}`}>
              {renderLink(langCode, locale, location, data.site.siteMetadata.siteUrl)}
            </Col>
          ))}
        </Row>
      )}
    />
  );
}

LanguageLinks.propTypes = {
  locale: PropTypes.string.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
};

export default LanguageLinks;
