import React from 'react';
import PropTypes from 'prop-types';
import { config } from '@fortawesome/fontawesome-svg-core';
import { StaticQuery, graphql } from 'gatsby';
import 'styles/bootstrap.scss';
import Helmet from 'react-helmet';
import { lumosBlue } from '@lumoslabs/react_lux';
import '@fortawesome/fontawesome-svg-core/styles.css';
import EventdInit from '../utils/EventdInit';
import { Header, Footer } from './ui';
import Localizer from './utils/Localizer';
import ROLLBAR from './utils/Rollbar';

config.autoAddCss = false;

const mobileClientUserAgents = ['Lumosity', 'Daylight Client', 'Creativity', 'Figment', 'Thrive'];

function Layout({ locale, location, children, isStickyHeader, accentColor, footerOptions, forceWhiteHeader }) {
  function renderedAsWebview() {
    const uaString = typeof window !== 'undefined' && window.navigator.userAgent;
    const isWebview = mobileClientUserAgents.some((clientString) => uaString && uaString.includes(clientString));
    const webviewParam = location.href && location.href.includes('layout=webview');
    return isWebview || webviewParam;
  }
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              siteUrl
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet titleTemplate="%s" defaultTitle={data.site.siteMetadata.title} />
          <Helmet meta={[{ property: 'og:url', content: `${data.site.siteMetadata.siteUrl}${location.pathname}` }]} />
          <Helmet>
            <html lang={locale} data-version={process.env.COMMIT_REF || 'dev'} />
            <link
              rel="shortcut icon"
              type="image/x-icon"
              href="https://asset.lumosity.com/resources/static/favicon.ico"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="57x57"
              href="https://asset.lumosity.com/resources/static/apple-icons/touch-icon-iphone-57.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="72x72"
              href="https://asset.lumosity.com/resources/static/apple-icons/touch-icon-iphone-72.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="114x114"
              href="https://asset.lumosity.com/resources/static/touch-icon-iphone-114.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="144x144"
              href="https://asset.lumosity.com/resources/static/apple-icons/touch-icon-iphone-144.png"
            />
            <script type="text/javascript">{ROLLBAR}</script>
          </Helmet>
          <EventdInit location={location} />
          <Localizer locale={locale} location={location} />
          <Header
            isStickyHeader={isStickyHeader}
            locale={locale}
            accentColor={accentColor}
            renderedAsWebview={renderedAsWebview()}
            forceWhiteBackground={forceWhiteHeader}
            location={location}
          />
          {children}
          <Footer
            locale={locale}
            location={location}
            renderLanguageLinks={footerOptions.renderLanguageLinks}
            renderedAsWebview={renderedAsWebview()}
          />
        </>
      )}
    />
  );
}

Layout.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  locale: PropTypes.string,
  children: PropTypes.node.isRequired,
  isStickyHeader: PropTypes.bool,
  accentColor: PropTypes.string,
  footerOptions: PropTypes.shape({
    renderLanguageLinks: PropTypes.bool
  }),
  forceWhiteHeader: PropTypes.bool
};

Layout.defaultProps = {
  locale: 'en',
  isStickyHeader: false,
  accentColor: lumosBlue,
  footerOptions: {},
  forceWhiteHeader: false
};

export default Layout;
