import React from 'react';
import PropTypes from 'prop-types';
import { gray1 } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row } from 'reactstrap';

// child components
import LinkGroups from './LinkGroups';
import Copyright from './Copyright';
import LanguageLinks from './LanguageLinks';
import ScrollToTopLink from './ScrollToTopLink';

// styles
import * as layout from '../../../styles/helpers/layout';

const styles = StyleSheet.create({
  footer: {
    ...layout.defaultContainerProperties,
    backgroundColor: gray1
  },
  siteMapRow: {
    maxHeight: 450,
    height: 450,
    '@media (max-width: 767.98px)': {
      maxHeight: 'none',
      height: 'auto'
    }
  },
  copyrightRow: {
    ...layout.alignLeft
  }
});

function Footer({ locale, location, renderLanguageLinks, renderedAsWebview }) {
  if (renderedAsWebview) {
    return <div />;
  }

  return (
    <footer className={css(styles.footer)}>
      <Container>
        <Row className={css(styles.siteMapRow)}>
          <LinkGroups locale={locale} />
        </Row>
        {renderLanguageLinks && <LanguageLinks locale={locale} location={location} />}
        <Row className={css(styles.copyrightRow)}>
          <Copyright locale={locale} />
          <ScrollToTopLink />
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  locale: PropTypes.string,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired
  }).isRequired,
  renderLanguageLinks: PropTypes.bool,
  renderedAsWebview: PropTypes.bool
};

Footer.defaultProps = {
  locale: 'en',
  renderLanguageLinks: true,
  renderedAsWebview: false
};

export default Footer;
