import React from 'react';
import i18n from '../../../../utils/i18n';
import { mindfulnessUrl, figmentUrlFooter } from '../../utils/LinkUtils';
import SSGLink from '../Link/SSGLink';
import LinkGroup from '../LinkGroup';
import NonSSGLink from '../Link/NonSSGLink';
import * as enOnly from '../../../../locales/enOnly.json';

const OurOtherAppsLinkGroup = () => (
  <LinkGroup headerText={enOnly.OurOtherApps}>
    <NonSSGLink to={figmentUrlFooter()} data-track-link-click="goto_figment">
      {enOnly.Figment.title}
    </NonSSGLink>
    <SSGLink to={mindfulnessUrl()} data-track-link-click="goto_mindfulness">
      {i18n.t('footer.mindfulness')}
    </SSGLink>
  </LinkGroup>
);

export default OurOtherAppsLinkGroup;
