import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import { fontStyles } from '@lumoslabs/react_lux';

function HeaderText({ size, styles, children }) {
  const HeaderElement = `h${size}`;
  return <HeaderElement className={css(fontStyles[HeaderElement], styles)}>{children}</HeaderElement>;
}

HeaderText.propTypes = {
  // TODO: Add validations that this number is between 1 and 6 (i.e. all possible header sizes)
  size: PropTypes.number.isRequired,
  styles: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node.isRequired
};

HeaderText.defaultProps = {
  styles: []
};

export default HeaderText;
