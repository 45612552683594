import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import PlayStoreEn from './images/play_store.png';
import PlayStoreDe from './images/play_store.de.png';
import PlayStoreEs from './images/play_store.es.png';
import PlayStoreFr from './images/play_store.fr.png';
import PlayStoreKo from './images/play_store.ko.png';
import PlayStoreJa from './images/play_store.ja.png';
import PlayStorePt from './images/play_store.pt.png';

const imageMap = {
  en: PlayStoreEn,
  fr: PlayStoreFr,
  es: PlayStoreEs,
  de: PlayStoreDe,
  ko: PlayStoreKo,
  ja: PlayStoreJa,
  pt: PlayStorePt
};

function GooglePlayStoreButton({ locale }) {
  return (
    <>
      <Button
        data-track-button-press="goto_play_store"
        src={imageMap[locale]}
        to="https://play.google.com/store/apps/details?id=com.lumoslabs.lumosity"
      />
    </>
  );
}

GooglePlayStoreButton.propTypes = {
  locale: PropTypes.string.isRequired
};

export default GooglePlayStoreButton;
