import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import PropTypes from 'prop-types';
import { lumosBlue, whiteAt98 } from '@lumoslabs/react_lux';

// style assets
import * as layout from '../../../styles/helpers/layout';

const bunCount = 2;

const styles = StyleSheet.create({
  hamburgerContainer: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 5,
    height: 56,
    width: 56,
    backgroundColor: 'transparent',
    [layout.mediaQuery.maxWidth.bootstrap.md]: {
      ...layout.centerHorizontal,
      ...layout.centerVertical,
      flexDirection: 'column'
    }
  },
  closedBun: {
    width: 18,
    height: 3,
    borderRadius: 6,
    display: 'block',
    cursor: 'pointer',
    transition: 'transform 200ms cubic-bezier(0.19, 1, 0.22, 1)',
    backgroundColor: whiteAt98,
    ':nth-of-type(2)': {
      marginTop: 3,
      marginBottom: 1
    }
  },
  openBun: {
    ':nth-of-type(1)': {
      transform: 'translateY(2.5px) rotate(45deg)'
    },
    ':nth-of-type(2)': {
      transform: 'translateY(-3px) rotate(135deg)'
    }
  },
  contrastBun: {
    backgroundColor: lumosBlue
  }
});

function HamburgerMenu({ toggleDropdown, showBlueBun, dropdownIsOpen }) {
  const buns = [...Array(bunCount)].map((_, index) => (
    <span
      // eslint-disable-next-line react/no-array-index-key
      key={`bun${index}`}
      className={css(styles.closedBun, showBlueBun && styles.contrastBun, dropdownIsOpen && styles.openBun)}
    />
  ));
  return (
    <div
      role="presentation"
      className={css(styles.hamburgerContainer)}
      onMouseUp={() => toggleDropdown()}
      onKeyUp={() => toggleDropdown()}
    >
      {buns}
    </div>
  );
}

HamburgerMenu.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  showBlueBun: PropTypes.bool.isRequired,
  dropdownIsOpen: PropTypes.bool.isRequired
};

export default HamburgerMenu;
