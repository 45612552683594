import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { RoundedButton, OutlineButton, lumosOrange, whiteAt98, blackAt62, fonts } from '@lumoslabs/react_lux';
import { css, StyleSheet } from 'aphrodite';

// utils
import i18n from '../../../utils/i18n';
import {
  gamesUrl,
  showLogin,
  showGetStarted,
  showStartTraining,
  scienceUrl,
  signupUrl,
  startTrainingUrl,
  loginUrl
} from '../utils/LinkUtils';

// styles
import * as buttons from '../../../styles/helpers/buttons';

const styles = StyleSheet.create({
  headerLink: {
    ...fonts.smallCaption,
    textTransform: 'uppercase',
    color: whiteAt98,
    whiteSpace: 'nowrap',
    fontWeight: 700,
    marginLeft: 12,
    ':hover': {
      textDecoration: 'underline'
    }
  },
  contrastLink: {
    color: blackAt62
  },
  roundedButton: {
    ...buttons.headerButtonDefaults,
    marginLeft: 12
  },
  outlineButton: {
    ...buttons.headerOutlineButtonDefaults,
    marginLeft: 12
  },
  contrastOutlineButton: {
    color: lumosOrange,
    borderColor: lumosOrange,
    ':hover': {
      color: whiteAt98,
      backgroundColor: lumosOrange
    }
  }
});

function LinkGroup({ applyContrastStyling, accentColor, locale, loggedIn }) {
  const accentStyles = StyleSheet.create({
    accentButton: {
      ':hover': {
        color: accentColor
      }
    }
  });

  return (
    <>
      <a
        href={gamesUrl(locale, loggedIn)}
        data-track
        data-track-location="header"
        data-track-link-click="goto_brain_games"
        className={css(styles.headerLink, applyContrastStyling && styles.contrastLink)}
      >
        {i18n.t('header.links.brainGames')}
      </a>
      <Link
        to={scienceUrl(locale)}
        data-track
        data-track-location="header"
        data-track-link-click="goto_science"
        className={css(styles.headerLink, applyContrastStyling && styles.contrastLink)}
      >
        {i18n.t('header.links.ourScience')}
      </Link>
      {showLogin(loggedIn) && (
        <OutlineButton
          value={i18n.t('header.links.logIn')}
          styles={[
            styles.outlineButton,
            accentStyles.accentButton,
            applyContrastStyling && styles.contrastOutlineButton
          ]}
          href={loginUrl()}
          track="true"
          trackButtonPress="log_in"
          trackLocation="header"
        />
      )}
      {showGetStarted(loggedIn) && (
        <RoundedButton
          value={i18n.t('header.links.getStarted')}
          styles={[styles.roundedButton]}
          href={signupUrl()}
          track="true"
          trackButtonPress="goto_signup_start_training"
          trackLocation="header"
        />
      )}
      {showStartTraining(loggedIn) && (
        <RoundedButton
          value={i18n.t('header.links.startTraining')}
          styles={[styles.roundedButton]}
          href={startTrainingUrl()}
          track="true"
          trackButtonPress="goto_start_training"
          trackLocation="header"
        />
      )}
    </>
  );
}

LinkGroup.defaultProps = {
  locale: 'en',
  loggedIn: false
};

LinkGroup.propTypes = {
  applyContrastStyling: PropTypes.bool.isRequired,
  accentColor: PropTypes.string.isRequired,
  locale: PropTypes.string,
  loggedIn: PropTypes.bool
};

export default LinkGroup;
