import React from 'react';
import PropTypes from 'prop-types';
import { AppStoreButton, GooglePlayStoreButton } from '../StoreButton';
import LinkGroup from '../LinkGroup';
import i18n from '../../../../utils/i18n';

function MobileLinkGroup({ locale }) {
  return (
    <LinkGroup headerText={i18n.t('footer.mobileApps')}>
      <AppStoreButton locale={locale} />
      <GooglePlayStoreButton locale={locale} />
    </LinkGroup>
  );
}

MobileLinkGroup.propTypes = {
  locale: PropTypes.string.isRequired
};

export default MobileLinkGroup;
