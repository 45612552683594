import React from 'react';
import { Link } from 'gatsby';
import { css } from 'aphrodite';

import Styles from './Styles';

// Use SSGLink for links that resolve to ssg pages
function SSGLink({ children, to, ...attrs }) {
  return (
    <Link className={css(Styles.link)} to={to} data-track data-track-location="footer" {...attrs}>
      {children}
    </Link>
  );
}

export default SSGLink;
