import React from 'react'
import NonSSGLink from '../Link/NonSSGLink'
import LinkGroup from '../LinkGroup'
import i18n from '../../../../utils/i18n'

export default () => {
  return (
    <LinkGroup headerText={i18n.t(`footer.sciencePartners`)}>
      <NonSSGLink
        to='/hcp/research/about'
        data-track-link-click='hcp_researchers'
      >
        {i18n.t(`footer.becomeACollaborator`)}
      </NonSSGLink>
      <NonSSGLink
        to='/hcp'
        data-track-link-click='hcp_overview'
      >
        {i18n.t(`footer.humanCognitionProject`)}
      </NonSSGLink>
    </LinkGroup>
  )
}
