import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import withCurrentUser from 'components/utils/withCurrentUser';
import LumosityLinkGroup from './Lumosity';
import LumosLabsLinkGroup from './LumosLabs';
import OurOtherAppsLinkGroup from './OurOtherApps';
import MobileLinkGroup from './Mobile';
import SciencePartnersLinkGroup from './SciencePartners';
import HelpLinkGroup from './Help';
import LegalLinkGroup from './Legal';

const LinkGroups = ({ locale, currentUser }) => {
  const loggedIn = currentUser.userLoggedIn;
  return (
    <>
      <Col md="4">
        <LumosityLinkGroup locale={locale} loggedIn={loggedIn} />
        <LumosLabsLinkGroup locale={locale} />
      </Col>
      <Col md="4">
        <MobileLinkGroup locale={locale} />
        {locale === 'en' && <OurOtherAppsLinkGroup locale={locale} />}
        <SciencePartnersLinkGroup />
      </Col>
      <Col md="4">
        <HelpLinkGroup locale={locale} />
        <LegalLinkGroup locale={locale} />
      </Col>
    </>
  );
};

LinkGroups.propTypes = {
  locale: PropTypes.string,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    preferredLanguage: PropTypes.string,
    userLoggedIn: PropTypes.bool
  })
};

LinkGroups.defaultProps = {
  locale: 'en',
  currentUser: null
};

export default withCurrentUser(LinkGroups);
