// TODO: refactor to ReactLux
/*
TODO: probably no real need for a separate set of defaults for header buttons,
just need to ensure that the fonts are overridden
*/
import { lumosOrange, whiteAt98, fonts } from '@lumoslabs/react_lux';
import { StyleSheet } from 'aphrodite';

import * as layout from './layout';

export const buttonDefaults = {
  ...fonts.p,
  fontWeight: 700,
  minWidth: 264,
  textAlign: 'center',
  boxSizing: 'border-box',
  paddingTop: 12,
  color: whiteAt98,
  [layout.mediaQuery.maxWidth.tablet]: {
    minWidth: 224
  }
};

export const outlineButtonDefaults = {
  ...buttonDefaults,
  backgroundColor: 'transparent',
  transition: 'background-color 200ms linear',
  color: lumosOrange,
  border: `2px solid ${lumosOrange}`,
  textTransform: 'none',
  paddingTop: 11,
  ':hover': {
    color: whiteAt98,
    backgroundColor: lumosOrange
  }
};

export const extendedButtonDefaults = {
  minWidth: 288,
  [layout.mediaQuery.maxWidth.tablet]: {
    minWidth: 272
  },
  [layout.mediaQuery.maxWidth.mobileLarge]: {
    minWidth: 260
  }
};

export const truncatedButtonDefaults = {
  ...buttonDefaults,
  minWidth: 168,
  [layout.mediaQuery.maxWidth.tablet]: {
    minWidth: 144
  }
};

export const headerButtonDefaults = {
  ...fonts.smallCaption,
  fontWeight: 700,
  color: whiteAt98,
  boxSizing: 'border-box',
  lineHeight: 1.21,
  textTransform: 'uppercase',
  paddingTop: 13,
  paddingBottom: 12,
  [layout.mediaQuery.maxWidth.tablet]: {
    paddingBottom: 11
  }
};

export const headerOutlineButtonDefaults = {
  ...headerButtonDefaults,
  background: 'transparent',
  paddingTop: 11,
  paddingBottom: 10,
  border: `2px solid ${whiteAt98}`,
  [layout.mediaQuery.maxWidth.tablet]: {
    paddingTop: 9,
    paddingBottom: 7
  },
  ':hover': {
    backgroundColor: whiteAt98,
    color: lumosOrange
  }
};

const buttonStyles = StyleSheet.create({
  buttonDefaults,
  outlineButtonDefaults,
  extendedButtonDefaults,
  truncatedButtonDefaults,
  headerButtonDefaults,
  headerOutlineButtonDefaults
});

export default buttonStyles;

// default button content for easy and safe copy/past
/*
              value={i18n.t('DEFAULT')}
              styles={[]}
              track='true'
              trackButton='DEFAULT'
              trackLocation='DEFAULT'
              href='/'
*/

// default link content for easy and safe copy/past
/*
              value={i18n.t('DEFAULT')}
              styles={[]}
              track='true'
              trackLink='DEFAULT'
              trackLocation='DEFAULT'
              href='/'
*/
