import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { blackAt62, fonts, gray2 } from '@lumoslabs/react_lux';
import i18n from '../../../utils/i18n';
import { centerVertical } from '../../../styles/helpers/layout';

const styles = StyleSheet.create({
  link: {
    ...centerVertical,
    ...fonts.largeCaption,
    height: 50,
    width: '100%',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    transition: 'color 100ms cubic-bezier(0.19, 1, 0.22, 1)',
    color: 'transparent'
  },
  linkVisible: {
    color: blackAt62,
    transition: 'color 2000ms cubic-bezier(0.19, 1, 0.22, 1)'
  },
  bottomBorder: {
    borderBottom: `1px solid ${gray2}`
  }
});

function DropdownGroupItem({ to, content, eventName, dropdownIsOpen, lastInSequence, toggleDropdown }) {
  return (
    <a
      href={to}
      data-track
      data-track-location="header"
      data-track-link-click={eventName}
      className={css(styles.link, dropdownIsOpen && styles.linkVisible, !lastInSequence && styles.bottomBorder)}
      onClick={() => dropdownIsOpen && toggleDropdown()}
    >
      {i18n.t(`header.links.${content}`)}
    </a>
  );
}

DropdownGroupItem.propTypes = {
  to: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  dropdownIsOpen: PropTypes.bool.isRequired,
  lastInSequence: PropTypes.bool,
  toggleDropdown: PropTypes.func.isRequired
};

DropdownGroupItem.defaultProps = {
  lastInSequence: false
};

export default DropdownGroupItem;
