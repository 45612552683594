import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Container, Row } from 'reactstrap';

// style assets
import { whiteAt98 } from '@lumoslabs/react_lux';
import * as layout from '../../../styles/helpers/layout';

// components
import DropdownGroupItem from './DropdownGroupItem';

// utils
import {
  showGetStarted,
  showStartTraining,
  showLogin,
  scienceUrl,
  gamesUrl,
  loginUrl,
  startTrainingUrl,
  signupUrl
} from '../utils/LinkUtils';

// this will need to be updated if items are added to / removed from the dropdown
const dropdownHeight = 520;

const styles = StyleSheet.create({
  container: {
    display: 'none',
    [layout.mediaQuery.maxWidth.bootstrap.md]: {
      display: 'block'
    },
    backgroundColor: whiteAt98,
    width: '100vw',
    height: 'auto',
    position: 'fixed',
    top: dropdownHeight * -1,
    left: 0,
    zIndex: 3,
    boxSizing: 'border-box',
    boxShadow: '0 1.2px 1px 0 rgba(0,0,0,0.2)',
    transition: 'top 800ms cubic-bezier(0.19, 1, 0.22, 1)'
  },
  dropdownIsOpen: {
    top: 0,
    transition: 'top 500ms cubic-bezier(0.19, 1, 0.22, 1)'
  },
  itemContainer: {
    width: '100vw',
    height: '50px',
    padding: '0 16px',
    boxSizing: 'border-box'
  },
  headerOffset: {
    width: '100vw',
    height: 56,
    padding: '0 16px',
    boxSizing: 'border-box'
  }
});

function DropdownGroup({ dropdownIsOpen, locale, toggleDropdown, loggedIn }) {
  const wrapperRef = useRef(null);
  // Hook that checks for clicks outside the menu
  function clickedOutside(ref) {
    // Alert if clicked outside of element
    function handleClickOutside(event) {
      if (dropdownIsOpen && ref.current && !ref.current.contains(event.target)) {
        toggleDropdown();
      }
    }
    useEffect(() => {
      // Bind the event listener
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mouseup', handleClickOutside);
      };
    });
  }
  clickedOutside(wrapperRef);
  return (
    <div ref={wrapperRef}>
      <Container fluid className={css(styles.container, dropdownIsOpen && styles.dropdownIsOpen)}>
        <Row className={css(styles.headerOffset)} />
        {showGetStarted(loggedIn) && (
          <Row className={css(styles.itemContainer)} key="getStarted" data-cy="dropdown-group">
            <DropdownGroupItem
              to={signupUrl()}
              content="getStarted"
              eventName="goto_signup"
              dropdownIsOpen={dropdownIsOpen}
              lastInSequence={false}
              toggleDropdown={toggleDropdown}
            />
          </Row>
        )}
        {showStartTraining(loggedIn) && (
          <Row className={css(styles.itemContainer)} key="startTraining" data-cy="dropdown-group">
            <DropdownGroupItem
              to={startTrainingUrl()}
              content="startTraining"
              eventName="goto_start_training"
              dropdownIsOpen={dropdownIsOpen}
              lastInSequence={false}
              toggleDropdown={toggleDropdown}
            />
          </Row>
        )}
        {showLogin(loggedIn) && (
          <Row className={css(styles.itemContainer)} key="logIn" data-cy="dropdown-group">
            <DropdownGroupItem
              to={loginUrl()}
              content="logIn"
              eventName="goto_login"
              dropdownIsOpen={dropdownIsOpen}
              lastInSequence={false}
              toggleDropdown={toggleDropdown}
            />
          </Row>
        )}
        <Row className={css(styles.itemContainer)} key="ourScience" data-cy="dropdown-group">
          <DropdownGroupItem
            to={scienceUrl(locale)}
            content="ourScience"
            eventName="goto_science"
            dropdownIsOpen={dropdownIsOpen}
            lastInSequence={false}
            toggleDropdown={toggleDropdown}
          />
        </Row>
        <Row className={css(styles.itemContainer)} key="brainGames" data-cy="dropdown-group">
          <DropdownGroupItem
            to={gamesUrl(locale, loggedIn)}
            content="brainGames"
            eventName="goto_brain_games"
            dropdownIsOpen={dropdownIsOpen}
            lastInSequence
            toggleDropdown={toggleDropdown}
          />
        </Row>
      </Container>
    </div>
  );
}

// default properties are made available to offer this component to static headers
DropdownGroup.defaultProps = {
  dropdownIsOpen: false,
  locale: 'en',
  loggedIn: false
};

DropdownGroup.propTypes = {
  dropdownIsOpen: PropTypes.bool,
  locale: PropTypes.string,
  toggleDropdown: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool
};

export default DropdownGroup;
