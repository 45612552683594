// FIXME: Figure out how to read from dotenv, Gatsby needs a file at .env.${NODE_ENV} to make the variables available
// client side
const localesSupported = 'en,es,fr,de,ja,pt,ko'
export default {
  name: 'urlDetector',
  lookup(_options) {
    if (typeof window === "undefined") return;
    const [_preslash, possibleLang] = window.location.pathname.split('/')
    if(localesSupported.includes(possibleLang)) return possibleLang
  }
}
