// TODO: abstract to ReactLux
import { StyleSheet } from 'aphrodite';

export const breakpoints = {
  mobileSmall: 320,
  mobile: 375,
  mobileLarge: 576,
  tablet: 768,
  tabletLarge: 1024,
  desktopSmall: 992,
  desktop: 1200,
  desktopLarge: 2100
};

const breakpointsBoostrap = {
  sm: breakpoints.mobileLarge,
  md: breakpoints.tablet,
  lg: breakpoints.desktopSmall,
  xl: breakpoints.desktop
};

export const mediaQuery = {
  maxWidth: {
    mobileSmall: `@media (max-width:${breakpoints.mobileSmall}px)`,
    mobile: `@media (max-width:${breakpoints.mobile}px)`,
    mobileLarge: `@media (max-width:${breakpoints.mobileLarge}px)`,
    tablet: `@media (max-width:${breakpoints.tablet}px)`,
    tabletLarge: `@media (max-width:${breakpoints.tabletLarge}px)`,
    desktopSmall: `@media (max-width:${breakpoints.desktopSmall}px)`,
    desktop: `@media (max-width:${breakpoints.desktop}px)`,
    desktopLarge: `@media (max-width:${breakpoints.desktopLarge}px)`,
    bootstrap: {
      sm: `@media (max-width:${breakpointsBoostrap.sm - 0.2}px)`,
      md: `@media (max-width:${breakpointsBoostrap.md - 0.2}px)`,
      lg: `@media (max-width:${breakpointsBoostrap.lg - 0.2}px)`,
      xl: `@media (max-width:${breakpointsBoostrap.xl - 0.2}px)`
    }
  },
  minWidth: {
    mobileSmall: `@media (min-width:${breakpoints.mobileSmall}px)`,
    mobile: `@media (min-width:${breakpoints.mobile}px)`,
    mobileLarge: `@media (min-width:${breakpoints.mobileLarge}px)`,
    tablet: `@media (min-width:${breakpoints.tablet}px)`,
    tabletLarge: `@media (min-width:${breakpoints.tabletLarge}px)`,
    desktopSmall: `@media (min-width:${breakpoints.desktopSmall}px)`,
    desktop: `@media (min-width:${breakpoints.desktop}px)`,
    desktopLarge: `@media (min-width:${breakpoints.desktopLarge}px)`
  },
  bootstrap: {
    sm: `@media (min-width:${breakpointsBoostrap.sm}px)`,
    md: `@media (min-width:${breakpointsBoostrap.md}px)`,
    lg: `@media (min-width:${breakpointsBoostrap.lg}px)`,
    xl: `@media (min-width:${breakpointsBoostrap.xl}px)`
  }
};

// to be used with top-level sections of landing pages
// scales horizontal whitespace between edge of screen top-level section content
// scales vertical whitespace between different top-level section content
export const defaultContainerProperties = {
  boxSizing: 'border-box',
  padding: '72px 102px',
  [mediaQuery.maxWidth.desktop]: {
    padding: '72px 40px'
  },
  [mediaQuery.maxWidth.tablet]: {
    padding: '48px 40px'
  },
  [mediaQuery.maxWidth.mobileLarge]: {
    padding: '32px 16px'
  }
};

// to be used for top-level sections that follow an overflow image that has negative margins
// this is a common pattern on the homepage
// this can't inherit from defaultContainerProperties due to mixed media queries
// could be reconciled if we rebuilt these as Maps objects (see Aphrodite README for more)
export const containerWithoutTopPadding = {
  boxSizing: 'border-box',
  padding: '0 72px 102px 72px',
  [mediaQuery.maxWidth.desktop]: {
    padding: '0 40px 72px 40px'
  },
  [mediaQuery.maxWidth.tablet]: {
    padding: '0 40px 48px 40px'
  },
  [mediaQuery.maxWidth.mobileLarge]: {
    padding: '0 16px 32px 16px'
  }
};

export const containerWithoutBottomPadding = {
  boxSizing: 'border-box',
  padding: '102px 72px 0 72px',
  [mediaQuery.maxWidth.desktop]: {
    padding: '72px 40px 0 40px'
  },
  [mediaQuery.maxWidth.tablet]: {
    padding: '48px 40px 0 40px'
  },
  [mediaQuery.maxWidth.mobileLarge]: {
    padding: '32px 16px 0 16px'
  }
};

// centerVertical will break in IE11 when used on containers with `flex-direction: column`
// follow the link for available workarounds: https://github.com/philipwalton/flexbugs#flexbug-2
export const centerVertical = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignContent: 'center'
};

export const centerHorizontal = {
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  justifyContent: 'center'
};

export const overflowImageContainer = {
  ...centerVertical,
  ...centerHorizontal,
  width: '100vw',
  position: 'relative',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw'
};

export const alignLeft = {
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'flex-start',
  justifyContent: 'flex-start'
};

export const alignTop = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  alignContent: 'flex-start'
};

export const alignRight = {
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'flex-end',
  justifyContent: 'flex-end'
};

export const layoutStyles = StyleSheet.create({
  defaultContainerProperties,
  containerWithoutTopPadding,
  overflowImageContainer,
  centerVertical,
  centerHorizontal,
  breakpointsBoostrap,
  alignRight,
  alignLeft
});

export default layoutStyles;
