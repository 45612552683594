import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from '../../../styles/helpers/layout';

function HiddenOnMobile({ children }) {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(
      !(window && window.matchMedia && window.matchMedia(`(min-width:${breakpoints.desktopSmall}px)`).matches)
    );
  }, []);

  if (isMobile) {
    return <></>;
  }

  return <>{children}</>;
}

HiddenOnMobile.propTypes = {
  children: PropTypes.node.isRequired
};

export default HiddenOnMobile;
