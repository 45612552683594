import React from 'react';
import PropTypes from 'prop-types';
import SSGLink from '../Link/SSGLink';
import LinkGroup from '../LinkGroup';
import i18n from '../../../../utils/i18n';

function LegalLinkGroup({ locale }) {
  return (
    <LinkGroup headerText={i18n.t('footer.legal')}>
      <SSGLink to={`/${locale}/legal/privacy_policy/`} data-track-link-click="privacy_policy">
        {i18n.t('footer.privacyPolicy')}
      </SSGLink>
      <SSGLink to={`/${locale}/legal/terms_of_service/`} data-track-link-click="terms_of_service">
        {i18n.t('footer.termsOfService')}
      </SSGLink>
      <SSGLink
        to={`/${locale}/legal/responsible_disclosure_policy/`}
        data-track-link-click="responsible_disclosure_policy"
      >
        {i18n.t('footer.responsibleDisclosurePolicy')}
      </SSGLink>
      <SSGLink
        to={`/${locale}/legal/privacy_policy/#what-information-we-collect`}
        data-track-link-click="ca_privacy_policy"
      >
        {i18n.t('footer.californiaPrivacy')}
      </SSGLink>
    </LinkGroup>
  );
}

LegalLinkGroup.propTypes = {
  locale: PropTypes.string.isRequired
};

export default LegalLinkGroup;
