import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// styles
import * as layout from '../../../styles/helpers/layout';

// images
import logoWhite from './images/logo_white.svg';
import logoBlue from './images/logo_blue.svg';

const styles = StyleSheet.create({
  logo: {
    height: 29,
    width: 120,
    [layout.mediaQuery.maxWidth.tablet]: {
      height: 24,
      width: 99.3
    },
    [layout.mediaQuery.maxWidth.mobileLarge]: {
      height: 20,
      width: 82.75
    }
  }
});

function LogoLink({ showBlueLogo, locale }) {
  const logoSrc = showBlueLogo ? logoBlue : logoWhite;
  return (
    <Link to={`/${locale}/`} data-track data-track-location="header" data-track-link-click="goto_homepage">
      <img src={logoSrc} className={css(styles.logo)} alt="Lumosity" />
    </Link>
  );
}

LogoLink.propTypes = {
  showBlueLogo: PropTypes.bool.isRequired,
  locale: PropTypes.string
};

LogoLink.defaultProps = {
  locale: 'en'
};

export default LogoLink;
